import { breakpoints } from '@web-for-marketing/react-ui';

export const roiFormStyles = {
    twoColumnFormContainer: {
        padding: '4.6rem 4%',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            padding: '2rem 2.4rem !important',
        },
    },
    heading: {
        marginBottom: '1rem',
    },
    formInput: {
        marginBottom: '2.4rem',
    },
    inputLabel: {
        marginBottom: '.8rem',
    },
    accordion: {
        boxShadow: 'none',
        zIndex: '1 !important',
        '&:before': {
            display: 'none',
        },
    },
    accordionSummary: {
        padding: '0',
        display: 'inline-flex',
    },
    accordionDetail: {
        flexDirection: 'column',
        padding: '0',
    },
    collapse: {
        border: 'none',
    },
    accordionExpanded: {
        margin: '1.2rem 0 0 !important',
    },
    accordionSummaryExpanded: {
        minHeight: 'auto !important',
        margin: '0 0 1rem !important',
    },
} as const;
