import React from 'react';
import { Grid, Accordion, AccordionSummary, AccordionDetails, Collapse } from '@mui/material';
import { Heading, Input, Text, Select, V2Button } from '@web-for-marketing/react-ui';
import { useAtom, useSetAtom } from 'jotai';
import { isMobileAtom } from '@atoms/appSettings';
import ROIAdvanceForm from './ROIAdvanceForm';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ClassNames } from '@emotion/react';
import { roiFormStyles as classes } from './styleObjects/ROIFormStyles';
import { roiIndustryValueAtom, roiUnitSystemAtom } from '@atoms/roiCalculator';
import { roiIndustryOptions } from './ROIIndustryOptions';
import { ROIIndustry, Unit } from '@models/ROICalculator';

interface ROIFormProps {
    headerSection?: boolean;
}

export default function ROIForm({ headerSection }: ROIFormProps): JSX.Element {
    const [isMobile] = useAtom(isMobileAtom);
    const [{ defaultValue, name }, setIndustryFormValue] = useAtom(roiIndustryValueAtom);
    const setUnitSystem = useSetAtom(roiUnitSystemAtom);

    const updateIndustryDefaultValue = (industry: ROIIndustry = name): void => {
        setUnitSystem(Unit.Imperial);
        const newValue = roiIndustryOptions.find((option) => option.name === industry);
        if (newValue) {
            setIndustryFormValue({ ...newValue });
        }
    };

    const resetIndustryDefaultValue = (industry: ROIIndustry = name): void => {
        const newValue = roiIndustryOptions.find((option) => option.name === industry);
        if (newValue) {
            setIndustryFormValue({
                ...newValue,
                defaultValue: {
                    numberOfVehicles: 0,
                    averageVisitsPerDay: 0,
                    averageVisitDuration: 0,
                    averageRevenuePerVisit: 0,
                },
                advancedFormDefaultValue: {
                    fuelCostPerVolume: 0,
                    fuelEconomy: 0,
                    distancePerDay: 0,
                    hourlyRate: 0,
                    averageOvertimePerDay: 0,
                },
            });
        }
    };

    return (
        <Grid item xs={12} md={6} css={classes.twoColumnFormContainer}>
            <Heading component={headerSection ? 'h1' : 'h2'} variant='h3' weight='bold' css={classes.heading}>
                Quantify your fleet’s efficiency potential
            </Heading>
            <Text weight='regular'>
                Experience firsthand the financial benefits achievable with Geotab’s Routing and Optimization platform.
            </Text>
            <ClassNames>
                {({ css }) => (
                    <>
                        <Accordion
                            defaultExpanded
                            css={classes.accordion}
                            disableGutters
                            TransitionComponent={Collapse}
                            TransitionProps={{ className: css(classes.collapse) }}
                            slotProps={{ heading: { component: 'h2' } }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon color='primary' />}
                                css={classes.accordionSummary}
                                aria-controls='basic-metrics-content'
                                id='basic-metrics-header'
                            >
                                <Text color='primary' weight='bold'>
                                    Basic metrics
                                </Text>
                            </AccordionSummary>
                            <AccordionDetails css={classes.accordionDetail}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Text color='primary' variant='body2' css={classes.inputLabel}>
                                            Select industry
                                        </Text>
                                        <Select
                                            id='ROI-Industry'
                                            label='Industry'
                                            required
                                            hiddenLabel
                                            value={name}
                                            placeholder='Select industry'
                                            native={isMobile}
                                            options={roiIndustryOptions.map((option) => option.name)}
                                            css={classes.formInput}
                                            onChange={(value: ROIIndustry) => updateIndustryDefaultValue(value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Text color='primary' variant='body2' css={classes.inputLabel}>
                                            Number of vehicles in your fleet
                                        </Text>
                                        <Input
                                            type='number'
                                            min={0}
                                            required
                                            hiddenLabel
                                            label='Number of vehicles in your fleet'
                                            placeholder='Enter number of vehicles in your fleet...'
                                            id='ROI-fleet-size'
                                            css={classes.formInput}
                                            value={defaultValue.numberOfVehicles.toString()}
                                            onChange={(e) =>
                                                setIndustryFormValue((prevValue) => ({
                                                    ...prevValue,
                                                    defaultValue: {
                                                        ...prevValue.defaultValue,
                                                        numberOfVehicles: parseFloat(e.target.value),
                                                    },
                                                }))
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Text color='primary' variant='body2' css={classes.inputLabel}>
                                            Average visits per day
                                        </Text>
                                        <Input
                                            type='number'
                                            min={0}
                                            required
                                            hiddenLabel
                                            label='Average visits per day'
                                            placeholder='Enter Average visits per day...'
                                            id='ROI-visit-per-day'
                                            css={classes.formInput}
                                            value={defaultValue.averageVisitsPerDay.toString()}
                                            onChange={(e) =>
                                                setIndustryFormValue((prevValue) => ({
                                                    ...prevValue,
                                                    defaultValue: {
                                                        ...prevValue.defaultValue,
                                                        averageVisitsPerDay: parseFloat(e.target.value),
                                                    },
                                                }))
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Text color='primary' variant='body2' css={classes.inputLabel}>
                                            Average revenue per visit
                                        </Text>
                                        <Input
                                            type='number'
                                            min={0}
                                            required
                                            hiddenLabel
                                            label='Average revenue per visit (in USD)'
                                            placeholder='Enter Average revenue per visit...'
                                            id='ROI-revenue-per-visit'
                                            css={classes.formInput}
                                            value={defaultValue.averageRevenuePerVisit.toString()}
                                            onChange={(e) =>
                                                setIndustryFormValue((prevValue) => ({
                                                    ...prevValue,
                                                    defaultValue: {
                                                        ...prevValue.defaultValue,
                                                        averageRevenuePerVisit: parseFloat(e.target.value),
                                                    },
                                                }))
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Text color='primary' variant='body2' css={classes.inputLabel}>
                                            Average visit duration
                                        </Text>
                                        <Input
                                            type='number'
                                            min={0}
                                            required
                                            hiddenLabel
                                            label='Average visit duration'
                                            placeholder='Enter Average visit duration...'
                                            id='ROI-visit-duration'
                                            css={classes.formInput}
                                            endAdornment='Minutes'
                                            value={defaultValue.averageVisitDuration.toString()}
                                            onChange={(e) =>
                                                setIndustryFormValue((prevValue) => ({
                                                    ...prevValue,
                                                    defaultValue: {
                                                        ...prevValue.defaultValue,
                                                        averageVisitDuration: parseFloat(e.target.value),
                                                    },
                                                }))
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            css={classes.accordion}
                            classes={{ expanded: css(classes.accordionExpanded) }}
                            TransitionComponent={Collapse}
                            TransitionProps={{ className: css(classes.collapse) }}
                        >
                            <AccordionSummary
                                css={classes.accordionSummary}
                                classes={{ expanded: css(classes.accordionSummaryExpanded) }}
                                expandIcon={<ExpandMoreIcon color='primary' />}
                                aria-controls='advanced-metrics-content'
                                id='advanced-metrics-header'
                            >
                                <Text color='primary' weight='bold'>
                                    Advanced metrics
                                </Text>
                            </AccordionSummary>
                            <AccordionDetails css={classes.accordionDetail}>
                                <ROIAdvanceForm />
                            </AccordionDetails>
                        </Accordion>
                        <div>
                            <V2Button variant='ghost' onClick={() => resetIndustryDefaultValue()}>
                                Reset form
                            </V2Button>
                        </div>
                    </>
                )}
            </ClassNames>
        </Grid>
    );
}
