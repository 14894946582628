import React from 'react';
import { Grid } from '@mui/material';
import ROIResult from './ROICalculator/ROIResult';
import ROIForm from './ROICalculator/ROIForm';
import { CustomSectionComponentProps } from '@models/customSection';
import { breakpoints, Text, v2Colors } from '@web-for-marketing/react-ui';
import { SectionContainer } from './SectionContainer';

const classes = {
    twoColumnResultContainer: {
        padding: '4.6rem 4%',
        backgroundColor: v2Colors.core.innovationDark,
        borderRadius: '0 .8rem .8rem 0',
        [`@media (max-width: ${breakpoints.md}px)`]: {
            borderRadius: '0 0 .8rem .8rem',
            padding: '2rem 2.4rem !important',
        },
    },
    borderWrapper: {
        border: `0.1rem solid ${v2Colors.border.activeSecondary}`,
        borderRadius: '0.8rem',
    },
};

export default function ROICalculator({ headerSection }: CustomSectionComponentProps): JSX.Element {
    return (
        <SectionContainer>
            <Grid container css={classes.borderWrapper}>
                <ROIForm headerSection={headerSection} />
                <Grid item xs={12} md={6} css={classes.twoColumnResultContainer}>
                    <ROIResult />
                </Grid>
            </Grid>
            <Text color='primary' variant='body2' italic css={{ marginTop: '2.4rem' }}>
                Our ROI calculator uses advanced modelling and 20 years of evolution to offer accurate projections.
            </Text>
        </SectionContainer>
    );
}
